import React from 'react';
import { Link } from 'react-router-dom';
import './cabins.css';
import BookingModal from '../components/Booking';
import AliceCarousel from 'react-alice-carousel';
import "react-alice-carousel/lib/alice-carousel.css";

import sfimg1 from '../assets/images/salt_fork/CabinExt2-crop.jpg';
import sfimg2 from '../assets/images/salt_fork/chalet_cabin_ext_01.jpg';
import sfimg3 from '../assets/images/salt_fork/golf_rates_thumb.png';
import sfimg4 from '../assets/images/salt_fork/cabin_map.png';



const Cabins = () => {

  const handleDragStart = (e) => e.preventDefault();


  {/* Add more images here */}
  const slides = [
      <img src={sfimg1} onDragStart={handleDragStart} class="slider-img" role="presentation" />,
      <img src={sfimg2} onDragStart={handleDragStart} class="slider-img" role="presentation" />,
      <Link to="https://www.saltforkparklodge.com/recreation/golf">
        <img src={sfimg3} onDragStart={handleDragStart} class="slider-img" role="presentation" />
      </Link>,
      <img src={sfimg4} onDragStart={handleDragStart} class="slider-img" role="presentation" />
      
  ];

  const responsive_settings =  {
      0: {
          items: 1,
      },
      1024: {
          items: 3,
          itemsFit: 'contain',
      }
    };

  return (
  <div>
<div class="cabin-header-container">
<header>
          
           <h1 class="page-title">Ryan & Krista's Wedding</h1>
           <h3>Magnolia Spring Farm<br />June 1, 2024</h3>
            <nav>
                {/* disabling all links for now - only Info is public. Cabins is direct only */}
                {/* <Link to="/">Home</Link> */}
                <Link to="/">Main Wedding Page</Link>

            </nav>
            
        </header>
        </div>
  <div class="cabins-container">
      <div class='cabin-info'>
        

        <h1 class="cabin-h1">
          Join us at Salt Fork Lake!
        </h1>

        <div class="carousel-container">
        <AliceCarousel responsive={responsive_settings} autoPlay autoPlayInterval="5000" infinite items={slides} />
                        
                 
                  
                </div>
        <section>
          <article class="main-text">
            <div class="main-text-container">
      <p>
      Before the wedding, we would love to have our closest friends and family 
      together at Salt Fork Lake, so we have reserved a block of 14 cabins.
      </p>

      <p>
      They each hold up to 6 people in the 2 bedrooms, 
      and have a living room with couch, a kitchen, and a screened-in patio with a jacuzzi.
      
      All the cabins are lakeside, and have their own fire pit.
      </p>
      
      <p>
      We will be there Tuesday (5/28) through Thursday (5/30), 
      and are planning some fun festivities for those days, along with a BYOB BBQ for Thursday night.
      </p>

      <p>
      Friday we will be heading North to prepare for the wedding!
      </p>




      <br />
      <h1 class="cabin-h1">Booking Instructions:</h1>



      <p>
        <br />
        The 14 cabins we have reserved are at a discounted rate, so be sure to book early.
      </p>

      <p>
        5 CABINS & 1 PET-FRIENDLY CABINS REMAINING!
      </p>

      <p>To book a cabin, please contact Christie at Salt Fork Lake (contact info below) and mention you're with the Flinn/Norkus group. Once you've booked, it would be great if you would let us know, so we can keep track of cabin availability. </p>

      <div class="contact-info">
      <p>
        <span class="contact-name">Christie Bobbit</span><br />
        Sales and Catering Manager<br />
        Salt Fork Lodge and Conference Center<br />
        P: 740-435-9009<br />
        Cell: 740-801-8288<br />
        E: cbobbitt@greatohiolodges.com
      </p>
      </div>

      <p>Thank you and we look forward to seeing you there!</p>

      
      <h2>Additional details:</h2>

      <p>2 of the reserved cabins are pet-friendly.</p>

      <p>At time of booking, a deposit equal to a 1 night stay is due.</p>

      <p>**If we run out of reserved cabins, there may still be availability in the
      <a href="https://www.saltforkparklodge.com/lodging/cabins" target="_blank" rel="noopener noreferrer"> other cabins </a> 
      and/or hotel rooms in the 
      <a href="https://www.saltforkparklodge.com/lodging/lodge-rooms" target="_blank" rel="noopener noreferrer"> Lodge at Salt Fork. </a></p>

{/* 
      <p>We're still sorting out the specifics with Salt Fork,

       so please make sure you've provided your email address by 
       clicking <Link to="/rsvp"> here </Link> and we will keep you in the loop!</p>

       */}
      
{/*       
      -Rates for cabins/deposit.  

      -Dates we need to reserve by.

      -Final bookings are made through Salt Fork, we will provide contact info for this.  */}
<div class="call-to-action-container">
          <p class="call-to-action-text">
          Check out the <span class="cta-actual"><Link class="std-rsvp" to="/info">Wedding Info Page</Link></span> for details about the wedding weekend! 
          </p>
        </div>
        </div>
        </article>
        </section>

        {/* <CabinInfo /> */}

{/* 
        <p>PLEASE NOTE: This is just an RSVP to Ryan and Krista so they know how many cabins to hold. You will need to book the actual cabin yourself. More info at the below link:</p>
 */}


        {/* <BookingModal /> */}

       

      </div>

{/*       

      <p>
        (NEED TO CONFIRM)
        <br />
        Arrangements need to be made with Salt Fork by Thanksgiving/Dec/Jan, along with a deposit for the 1st night ($308 ?). 
        Filling out our form will let us know you plan to come - we will send you all info you need to book it - and reminders for deadlines.




      </p>
         */}
    


    <article class='cabin-links'>

      <br />

      <a
        class='cabin-link'
        href="https://www.saltforkparklodge.com/lodging/cabins"
        target="_blank"
        rel="noopener noreferrer"
      >
        General Cabins
      </a>

      <br />

      <a
        class='cabin-link'
        href="https://www.saltforkparklodge.com/lodging/cabins/chalet-cabins"
        target="_blank"
        rel="noopener noreferrer"
      >
        Chalet Cabins
      </a>

      <br />

      <a
        class='cabin-link'
        href="https://www.saltforkparklodge.com/lodging/lodge-rooms"
        target="_blank"
        rel="noopener noreferrer"
      >
        Lodge Rooms
      </a>

      <br />

      <a
        class='cabin-link'
        href="https://www.saltforkparklodge.com/recreation/property-activities"
        target="_blank"
        rel="noopener noreferrer"
      >
        Property Activities
      </a>

      <br />

      <a
        class='cabin-link'
        href="https://www.saltforkparklodge.com/recreation/marina"
        target="_blank"
        rel="noopener noreferrer"
      >
        Marina - Boat Rentals
      </a>

      <br />

      <a
        class='cabin-link'
        href="https://www.saltforkparklodge.com/recreation/golf"
        target="_blank"
        rel="noopener noreferrer"
      >
        Golf info
      </a>

      <br />

      <a
        class='cabin-link'
        href="https://www.saltforkparklodge.com/dining"
        target="_blank"
        rel="noopener noreferrer"
      >
        Dining info
      </a>

      <br />

      <a
        class='cabin-link'
        href="https://www.saltforkparklodge.com/about/faq"
        target="_blank"
        rel="noopener noreferrer"
      >
        FAQ
      </a>
    </article> 
    </div>
    </div>
  )
};

export default Cabins;