import React from 'react';
import Header from '../components/Header';
import { Link } from 'react-router-dom';
import AliceCarousel from 'react-alice-carousel';
import "react-alice-carousel/lib/alice-carousel.css";

import msfimg1 from '../assets/images/venue/msf_pic_hires.png';
import venue_map from '../assets/images/venue/map_wide.png';
import invite_map from '../assets/images/invites/Invitation Back.png';
import './info.css';
import PhotoGrid from '../components/PhotoGrid';
import InfoLinks from '../components/InfoLinks/InfoLinks';

const Info = () => {

  const handleDragStart = (e) => e.preventDefault();


  {/* Add more images here */}
  const slides = [
      <img src={msfimg1} onDragStart={handleDragStart} class="slider-img" role="presentation" />,
      <Link target='_blank' to="https://maps.app.goo.gl/Q1Rj6qmouS1Ao5Zz8"><img src={venue_map} class="slider-img" onDragStart={handleDragStart} role="presentation" /></Link>
  ];

  const responsive_settings =  {
      0: {
          items: 1,
          itemsFit: 'contain'
      },
      1024: {
          items: 1,
          itemsFit: 'contain'
      }
    };

    return (
        <div class="info-container">
        {/* Content here   */}

        <Header />
        
        <div class='info-info'>
        
        <h1 class="info-h1">General Information</h1>
       
       


        <div class="carousel-container">
        <AliceCarousel responsive={responsive_settings} autoPlay autoPlayInterval="5000" infinite items={slides} />
                                    
        
          </div>
    

        <div class="info-item">
        
      
          <p class="info-text">
            <div class="info-text-container">
            <img src={invite_map} onDragStart={handleDragStart} class="invite-map" role="presentation" />
          <h2>Venue</h2>
        
          
          <b>Magnolia Spring Farm</b>
          <br />
          <a 
            class='info-link'
            href="https://maps.app.goo.gl/Q1Rj6qmouS1Ao5Zz8" 
            target='_blank' 
            rel='noopener noreferrer'
          >
          6098 SR-183, Magnolia, OH 44643
          </a>
            
            <a
            class='info-link'
            href="https://www.magnoliaspringfarm.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            www.magnoliaspringfarm.com
          </a>

          <hr></hr>
         
          <h2>Ceremony/Reception</h2>
          
        
          Saturday June 1, 2024
            <br/>
            Ceremony time: 4:00 pm
            <br />
            Reception to follow 
           
            <hr></hr>
            
          <h2>Airports</h2>
          CAK - Akron/Canton (23 miles)
          <a 
            class='directions-link'
            href="https://www.google.com/maps/dir/Akron-Canton+Airport+(CAK),+5400+Lauby+Rd+NW,+North+Canton,+OH+44720/Magnolia+Spring+Farm,+Ohio+183,+Magnolia,+OH/@40.7710402,-81.5587977,11z/data=!3m1!4b1!4m13!4m12!1m5!1m1!1s0x8836d47bac9c5887:0x4b5fdee88369aec5!2m2!1d-81.4416324!2d40.9153709!1m5!1m1!1s0x8836c3a76e0f1f91:0x3fc52cc18a4cdca2!2m2!1d-81.3349402!2d40.6405496?entry=ttu"
            target="_blank"
            rel="noopener noreferrer">Directions to Venue</a> <br />
          CLE - Cleveland (72 miles)
          <a 
            class='directions-link'
            href="https://www.google.com/maps/dir/Cleveland+Hopkins+International+Airport+(CLE),+Riverside+Drive,+Cleveland,+OH/Magnolia+Spring+Farm,+Ohio+183,+Magnolia,+OH/@41.0323859,-81.9240338,10z/data=!3m1!4b1!4m13!4m12!1m5!1m1!1s0x8830eced544890cd:0xbbaf031e9364e392!2m2!1d-81.8538669!2d41.4057985!1m5!1m1!1s0x8836c3a76e0f1f91:0x3fc52cc18a4cdca2!2m2!1d-81.3349402!2d40.6405496?entry=ttu"
            target="_blank"
            rel="noopener noreferrer">Directions to Venue</a> <br />
          CMH - Columbus (126 miles)
          <a 
            class='directions-link'
            href="https://www.google.com/maps/dir/John+Glenn+Columbus+International+Airport+(CMH),+International+Gateway,+Columbus,+OH/Magnolia+Spring+Farm,+Ohio+183,+Magnolia,+OH/@40.3696103,-82.8144581,9z/data=!3m1!4b1!4m13!4m12!1m5!1m1!1s0x883862113489cee3:0xa07d02c8c11064c1!2m2!1d-82.8871767!2d39.9999399!1m5!1m1!1s0x8836c3a76e0f1f91:0x3fc52cc18a4cdca2!2m2!1d-81.3349402!2d40.6405496?entry=ttu"
            target="_blank"
            rel="noopener noreferrer">Directions to Venue</a> <br />
          PIT - Pittsburgh (70 miles)
          <a 
            class='directions-link'
            href="https://www.google.com/maps/dir/Pittsburgh+International+Airport+(PIT),+Airport+Boulevard,+Pittsburgh,+PA/Magnolia+Spring+Farm,+Ohio+183,+Magnolia,+OH/@40.5556019,-81.1158543,10z/data=!3m1!4b1!4m13!4m12!1m5!1m1!1s0x88345cc34ecd5b37:0xc812270d148d3f59!2m2!1d-80.2372941!2d40.4928542!1m5!1m1!1s0x8836c3a76e0f1f91:0x3fc52cc18a4cdca2!2m2!1d-81.3349402!2d40.6405496?entry=ttu"
            target="_blank"
            rel="noopener noreferrer">Directions to Venue</a> <br />
         
         <hr></hr>


          <h2>Lodging</h2>
         

          

          <div class="lodging-text">

            

            We're staying at an Airbnb next to the venue, but we have arranged a block of discounted rooms at a nearby hotel:

       

          </div>


          <div class="lodging-links">

          <div class="reserved-block">

          <h3>Reserved Hotel Block</h3>
         
          <a
            class='lodging-link'
            href="https://www.marriott.com/event-reservations/reservation-link.mi?id=1714487508841&key=GRP&app=resvlink"
            target="_blank"
            rel="noopener noreferrer"
          >
            TownePlace Suites (Marriott) - New Philadelphia
          </a>

          

          

          $149/night, Book by 4/30!<br /><br />
          Book online: 
          <a
            class='lodging-link'
            href="https://www.marriott.com/event-reservations/reservation-link.mi?id=1714487508841&key=GRP&app=resvlink"
            target="_blank"
            rel="noopener noreferrer"
          >
            CLICK HERE
          </a>
          or<br />
          Book by phone:<br />
          (330) 556-5354<br />
          and mention "FNW" (Flinn/Norkus Wedding)
        
          <br />
          {/* <span className="block-disclaimer">(or mention the Flinn/Norkus wedding when booking)</span> */}
          
          </div>

          <h3>Other nearby Hotels</h3>
          <a
            class='lodging-link'
            href="https://www.choicehotels.com/ohio/dover/comfort-inn-hotels/oh792"
            target="_blank"
            rel="noopener noreferrer"
          >
            Comfort Inn & Suites (Choice) - Dover
          </a>

          <a
            class='lodging-link'
            href="https://www.hilton.com/en/hotels/nphohhx-hampton-new-philadelphia/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Hampton Inn (Hilton) - New Philadelphia
          </a>
          
          <a
            class='lodging-link'
            href="https://www.marriott.com/en-us/hotels/cakfc-fairfield-inn-and-suites-canton-south/overview/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Fairfield Inn & Suites (Marriott) - South Canton
          </a>

          <a
            class='lodging-link'
            href="https://www.hilton.com/en/hotels/cakcodt-doubletree-canton-downtown/"
            target="_blank"
            rel="noopener noreferrer"
          >
            DoubleTree (Hilton) - Downtown Canton
          </a>
          

          <h3>Rental Houses</h3>

          <div class="lodging-text">

            We also recommend looking into Airbnb/Vrbo - there are some really great options in the area.
          </div>

          

        
            <span className="info-link">Airbnb:</span><a
            class='info-link'
            href="https://www.airbnb.com/s/magnolia--oh/homes"
            target="_blank"
            rel="noopener noreferrer"
          >
            Search near Magnolia
          </a>

          
          <span className="info-link">Vrbo:</span><a
            class='info-link'
            href="https://www.vrbo.com/search?destination=Magnolia%2C Ohio%2C United States of America"
            target="_blank"
            rel="noopener noreferrer"
          >
            Search near Magnolia
          </a>
          
          <hr></hr>


          
          <br />

          <h2>Nearby Stuff</h2>

          <InfoLinks />


          </div>


          </div>
          </p>

          
          </div>



          <div class="info-footer">

{/* <p>Check Back for More Information Soon!</p> */}
<br />
</div>

  
        </div>
        </div>
        
    )
};

export default Info;