import React, { useState } from 'react';
import Collapsible from 'react-collapsible';
import AliceCarousel from 'react-alice-carousel';
import "react-alice-carousel/lib/alice-carousel.css";

import "./style.css";

import house1 from '../../assets/images/houses/house1.png';
import house2 from '../../assets/images/houses/house2.png';
import house3 from '../../assets/images/houses/house3.png';
import house4 from '../../assets/images/houses/house4.png';
import house5 from '../../assets/images/houses/house5.png';
import house6 from '../../assets/images/houses/house6.png';
import house7 from '../../assets/images/houses/house7.png';
import house8 from '../../assets/images/houses/house8.png';
import house9 from '../../assets/images/houses/house9.png';
import house10 from '../../assets/images/houses/house10.png';
import house11 from '../../assets/images/houses/house11.png';
import house12 from '../../assets/images/houses/house12.png';
import house13 from '../../assets/images/houses/house13.png';
import house14 from '../../assets/images/houses/house14.png';
import house15 from '../../assets/images/houses/house15.png';
import house16 from '../../assets/images/houses/house16.png';
import house17 from '../../assets/images/houses/house17.png';
import house18 from '../../assets/images/houses/house18.png';
import house19 from '../../assets/images/houses/house19.png';
import house20 from '../../assets/images/houses/house20.png';



const MAG = "Magnolia, OH";
const NP = "New Philadelphia, OH";
const DOVER = "Dover, OH";
const STRAS = "Strasburg, OH";
const ZOAR = "Zoar, OH";
const BOLIV = "Bolivar, OH";
const MIN = "Mineral City, OH";
const MVA = "Minerva, OH";
const CANTON = "Canton, OH";
const AKRON = "Akron, OH";
const CF = "Cuyahoga Falls, OH";
const CLE = "Cleveland, OH";


const HOUSES = [
    {
        "name": "Bolivar Getaway - ***BOOKED: Kim***",
        "url": "https://www.airbnb.com/rooms/974691424996701354",
        "image": house3,
        "loc": BOLIV,
        "ppl": 7,
        "rooms": 3,
        "beds": 4,
        "bathrooms": 3,
        "price": 168
    },
    {
        "name": "Hilltop Cabin with Hot Tub - ***BOOKED: Doug***",
        "url": "https://www.airbnb.com/rooms/722525192516882357",
        "image": house4,
        "loc": BOLIV,
        "ppl": 12,
        "rooms": 4,
        "beds": 6,
        "bathrooms": 2.5,
        "price": 399
    },
    {
        "name": "The Hemlock Place",
        "url": "https://www.airbnb.com/rooms/684906275327173655",
        "image": house1,
        "loc": DOVER,
        "ppl": 14,
        "rooms": 4,
        "beds": 7,
        "bathrooms": 3.5,
        "price": 468
    },
    {
        "name": "Farm stay in Magnolia, Ohio",
        "url": "https://www.airbnb.com/rooms/31260650",
        "image": house2,
        "loc": MAG,
        "ppl": 16,
        "rooms": 4,
        "beds": 13,
        "bathrooms": 3,
        "price": 998
    },
    {
        "name": "The Trio- Unique Country Setting",
        "url": "https://www.airbnb.com/rooms/653121211057435553",
        "image": house11,
        "loc": DOVER,
        "ppl": 16,
        "rooms": 7,
        "beds": 10,
        "bathrooms": 5,
        "price": 895
    },
    {
        "name": "Lookout Lodge a house with a 360 view",
        "url": "https://www.airbnb.com/rooms/841019794301189649",
        "image": house18,
        "loc": DOVER,
        "ppl": 16,
        "rooms": 5,
        "beds": 10,
        "bathrooms": 7.5,
        "price": 795
    },
    {
        "name": "Travel Together Stay Together- Sleeps 14+",
        "url": "https://www.airbnb.com/rooms/24311083",
        "image": house19,
        "loc": NP,
        "ppl": 16,
        "rooms": 6,
        "beds": 9,
        "bathrooms": 3,
        "price": 850
    },
    {
        "name": "N & M Retreat",
        "url": "https://www.airbnb.com/rooms/860869295069515644",
        "image": house5,
        "loc": BOLIV,
        "ppl": 12,
        "rooms": 3,
        "beds": 7,
        "bathrooms": 1,
        "price": 120
    },
    {
        "name": "White Brick Regal Estate Home",
        "url": "https://www.airbnb.com/rooms/44417860",
        "image": house14,
        "loc": NP,
        "ppl": 10,
        "rooms": 4,
        "beds": 6,
        "bathrooms": 2,
        "price": 299
    },
    {
        "name": "Entire cottage in Minerva",
        "url": "https://www.airbnb.com/rooms/605801840585437142",
        "image": house15,
        "loc": MVA,
        "ppl": 10,
        "rooms": 4,
        "beds": 5,
        "bathrooms": 3,
        "price": 283
    },
    {
        "name": "Victorian Oasis Mineral City",
        "url": "https://www.airbnb.com/rooms/1051426427799062417",
        "image": house13,
        "loc": MIN,
        "ppl": 9,
        "rooms": 4,
        "beds": 4,
        "bathrooms": 2.5,
        "price": 150
    },
    {
        "name": "Charming 4-bedroom home away from home.",
        "url": "https://www.airbnb.com/rooms/52369691",
        "image": house17,
        "loc": BOLIV,
        "ppl": 8,
        "rooms": 4,
        "beds": 5,
        "bathrooms": 2,
        "price": 268
    },
    {
        "name": "The Lodge at Hilton's Hideaway",
        "url": "https://www.airbnb.com/rooms/1055822798478360579",
        "image": house12,
        "loc": DOVER,
        "ppl": 8,
        "rooms": 4,
        "beds": 4,
        "bathrooms": 3.5,
        "price": 743
    },
    {
        "name": "Comfortable B&B New Phila",
        "url": "https://www.airbnb.com/rooms/2006826",
        "image": house16,
        "loc": NP,
        "ppl": 10,
        "rooms": 4,
        "beds": 5,
        "bathrooms": 2,
        "price": 145
    },
    {
        "name": "The Hidden Valley",
        "url": "https://www.airbnb.com/rooms/874883569752962603",
        "image": house6,
        "loc": DOVER,
        "ppl": 6,
        "rooms": 3,
        "beds": 6,
        "bathrooms": 2,
        "price": 141
    },
    {
        "name": "Farmhouse Unique Country Setting",
        "url": "https://www.airbnb.com/rooms/37907795",
        "image": house7,
        "loc": DOVER,
        "ppl": 8,
        "rooms": 3,
        "beds": 5,
        "bathrooms": 2,
        "price": 250
    },
    {
        "name": "Historic home in Zoar - the Schoolmaster's House",
        "url": "https://www.airbnb.com/rooms/51891774",
        "image": house8,
        "loc": ZOAR,
        "ppl": 6,
        "rooms": 3,
        "beds": 5,
        "bathrooms": 1.5,
        "price": 170
    },
    {
        "name": "Cozy Guesthouse in the ❤ of Historic Zoar Village",
        "url": "https://www.airbnb.com/rooms/52664259",
        "image": house9,
        "loc": ZOAR,
        "ppl": 4,
        "rooms": 2,
        "beds": 2,
        "bathrooms": 3,
        "price": 155
    },
    {
        "name": "Garver House Bed and Breakfast",
        "url": "https://www.garverhousebnb.com/rooms-rates",
        "image": house10,
        "loc": STRAS,
        "ppl": 4,
        "rooms": 2,
        "beds": 2,
        "bathrooms": 2,
        "price": 295
    },
    {
        "name": "Historic Canton School of Fame",
        "url": "https://www.airbnb.com/rooms/854521084500118346",
        "image": house20,
        "loc": CANTON,
        "ppl": 16,
        "rooms": 14,
        "beds": 21,
        "bathrooms": 6.5,
        "price": 2050
    }

]

function make_link(link_data) {
    return (
        <div className="houselink-div">
            <a class="houselink" href={link_data.url} target="_blank" rel="noopener noreferrer">
            <div className="house-img"><img class="slider-img" src={link_data.image} /></div>
            <span className="house-name">{link_data.name}</span>
            </a><span className="house-loc"> - {link_data.loc}</span>
            

            <div className="details">
                <span>Guests: {link_data.ppl}</span>
                <span>Rooms: {link_data.rooms}</span>
                <span># of Beds: {link_data.beds}</span>
                <span>Bathrooms: {link_data.bathrooms}</span>
                <span>Price/night: {link_data.price}</span>
            </div>
        </div>
    )
}


export default function HouseLinks() {
    
    const handleDragStart = (e) => e.preventDefault();

    const responsive_settings =  {
        0: {
            items: 1,
        },
        1024: {
            items: 1,
            itemsFit: 'fill',
        }
      };

    return (
        <div className="houses-carousel">

<AliceCarousel mouseTracking responsive={responsive_settings} autoPlay autoPlayInterval="5000" infinite items={ HOUSES.map(make_link) } />

        </div>
    )

}