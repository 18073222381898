import React, { useState } from 'react';
import Collapsible from 'react-collapsible';

import "./style.css";

const NP = "New Philadelphia, OH";
const DOVER = "Dover, OH";
const CANTON = "Canton, OH";
const AKRON = "Akron, OH";
const CF = "Cuyahoga Falls, OH";
const CLE = "Cleveland, OH";

// MOVE THESE TO A SEPARATE FILE ?


const info_museum_links = [
    {
        "url": "https://thewarthermuseum.com/",
        "label": "Earnest Warther Museum & Gardens",
        "loc": DOVER
    },
    {
        "url": "https://www.profootballhof.com/",
        "label": "Pro Football Hall Of Fame",
        "loc": CANTON
    },
    {
        "url": "https://mckinleymuseum.org/",
        "label": "McKinley Presidential Library and Museum",
        "loc": CANTON
    },
    {
        "url": "http://cantonclassiccar.org/",
        "label": "Canton Classic Car Museum",
        "loc": CANTON
    },
    {
        "url": "https://www.visitcanton.com/directory/blue-water-majesty-museum/",
        "label": "Blue Water Majesty Museum",
        "loc": CANTON
    },
    {
        "url": "https://akronartmuseum.org/",
        "label": "Akron Art Museum",
        "loc": AKRON
    },
    {
        "url": "https://akronkids.org/",
        "label": "Akron Children's Museum",
        "loc": AKRON
    },
    {
        "url": "https://www.rockhall.com/",
        "label": "Rock & Roll Hall Of Fame",
        "loc": CLE
    },
    {
        "url": "https://www.clevelandart.org/",
        "label": "Cleveland Museum of Art",
        "loc": CLE
    },
    {
        "url": "https://greatscience.com/",
        "label": "Great Lakes Science Center",
        "loc": CLE
    },
    {
        "url": "https://www.cmnh.org/",
        "label": "Cleveland Museum of Natural History",
        "loc": CLE
    }
];

const info_historic_links = [
    {
        "url": "https://ohio.org/things-to-do/destinations/historic-schoenbrunn-village",
        "label": "Historic Schoenbrunn Village",
        "loc": NP
    },
    {
        "url": "http://www.doverhistory.org/",
        "label": "J.E. Reeves Victorian Home and Carraige House Museum",
        "loc": DOVER
    },
    {
        "url": "https://www.broadruncheese.com/",
        "label": "Broad Run Cheesehouse",
        "loc": DOVER
    },
    {
        "url": "https://stanhywet.org/",
        "label": "Stan Hywet Hall & Gardens",
        "loc": AKRON
    },
    {
        "url": "https://www.atlasobscura.com/places/goodyear-airdock",
        "label": "Goodyear Airdock",
        "loc": AKRON
    },
    {
        "url": "https://www.achristmasstoryhouse.com/",
        "label": "A Christmas Story House",
        "loc": CLE
    },
    {
        "url": "https://westsidemarket.org/",
        "label": "West Side Market",
        "loc": CLE
    },
    {
        "url": "https://holdenfg.org/",
        "label": "Cleveland Botanical Garden",
        "loc": CLE
    }
];

const info_parks_links = [
    {
        "url": "https://www.summitmetroparks.org/FA-Seiberling-Nature-Realm.aspx",
        "label": "F.A. Seiberling Nature Realm",
        "loc": AKRON
    },
    {
        "url": "https://www.summitmetroparks.org/gorge-metro-park.aspx",
        "label": "The Gorge",
        "loc": CF
    },
    {
        "url": "https://www.downtowncf.com/",
        "label": "Riverfront",
        "loc": CF
    },
    {
        "url": "https://www.nps.gov/cuva/index.htm",
        "label": "Cuyahoga Valley National Park",
        "loc": CF
    }
];

const info_theaters_links = [
    {
        "url": "https://www.ohioanderiecanalway.com/plan/listings/performing-arts-center-at-kent-state-university-tuscarawas/",
        "label": "Performing Arts Center",
        "loc": NP
    },
    {
        "url": "https://www.trumpetintheland.com/",
        "label": "Paul Green's Trumpet in the Land",
        "loc": NP
    },
    {
        "url": "https://cantonpalacetheatre.org/",
        "label": "Palace Theatre",
        "loc": CANTON
    },
    {
        "url": "https://akroncivic.com/",
        "label": "Civic Theatre",
        "loc": AKRON
    },
    {
        "url": "https://www.clevelandorchestra.com/discover/blossom-music-center/",
        "label": "Blossom Music Center",
        "loc": CF
    },
    {
        "url": "https://www.playhousesquare.org/",
        "label": "Playhouse Square",
        "loc": CLE
    }
];

const info_sports_links = [
    {
        "url": "https://www.midvalespeedway.com/index.cfm",
        "label": "Midvale Speedway",
        "loc": NP
    },
    {
        "url": "https://www.milb.com/akron/ballpark/canal-park-guide",
        "label": "Canal Park (Akron Aeros)",
        "loc": AKRON
    },
    {
        "url": "https://www.mlb.com/guardians/ballpark",
        "label": "Progressive Field (Cleveland Guardians)",
        "loc": CLE
    },
    {
        "url": "https://clevelandbrownsstadium.com/",
        "label": "First Energy Stadium (Cleveland Browns)",
        "loc": CLE
    },
    {
        "url": "https://www.rocketmortgagefieldhouse.com/",
        "label": "Rocket Mortgage Fieldhouse (Cleveland Cavaliers)",
        "loc": CLE
    }
];

const info_golf_links = [
    {
        "url": "https://www.oakshadowsgolf.com/",
        "label": "Oak Shadows Golf Club",
        "loc": NP
    },
    {
        "url": "https://www.zoarvillagegolf.com/",
        "label": "Zoar Golf Club",
        "loc": DOVER
    },
    {
        "url": "https://www.invitedclubs.com/clubs/firestone-country-club",
        "label": "Firestone Country Club",
        "loc": AKRON
    },
    {
        "url": "https://www.brookledgegc.com/",
        "label": "Brookledge Golf Course",
        "loc": CF
    }
];

const info_brew_links = [
    // {
    //     "url": "",
    //     "label": "Five Barrel Bullet Brewing",
    //     "loc": NP
    // },
    {
        "url": "https://www.broadwaybrewhouseohio.com/",
        "label": "Broadway Brewhouse",
        "loc": NP
    },
    {
        "url": "https://hoodletown.com/",
        "label": "Hoodletown Brewing",
        "loc": DOVER
    },
    {
        "url": "https://thebrewkettle.com/locations/canton/",
        "label": "The Brew Kettle (formerly Canton Brewing Co.)",
        "loc": CANTON
    },
    {
        "url": "https://www.missingmountain.com/",
        "label": "Missing Mountain",
        "loc": CF
    },
    {
        "url": "https://www.facebook.com/OhioBrewing/",
        "label": "Ohio Brewing",
        "loc": CF
    },
    {
        "url": "https://www.hihobrewingco.com/",
        "label": "Hi-Ho Brewing",
        "loc": CF
    },
    {
        "url": "https://www.greatlakesbrewing.com/",
        "label": "Great Lakes Brewing",
        "loc": CLE
    }
];

const info_wine_links = [
    {
        "url": "https://breitenbachwine.com/",
        "label": "Breitenbach Winery",
        "loc": DOVER
    },
    {
        "url": "https://www.swissheritagewinery.com/",
        "label": "Swiss Heritage Winery",
        "loc": DOVER
    },
    {
        "url": "https://www.silvermoonwinery.com/Default.asp",
        "label": "Silver Moon Winery",
        "loc": DOVER
    },
    {
        "url": "https://www.schoolhousewine.com/",
        "label": "School House Winery",
        "loc": DOVER
    },
    {
        "url": "https://gervasivineyard.com/",
        "label": "Gervasi Vineyard",
        "loc": CANTON
    }
];

const info_zoo_links = [
    {
        "url": "https://www.akronzoo.org/",
        "label": "Akron Zoo",
        "loc": AKRON
    },
    {
        "url": "https://www.clevelandmetroparks.com/zoo",
        "label": "Cleveland Zoo & Rainforest",
        "loc": CLE
    },
    {
        "url": "https://www.greaterclevelandaquarium.com/",
        "label": "Greater Cleveland Aquarium",
        "loc": CLE
    }
];

const info_other_links = [
    {
        "url": "https://www.woodstalltimberresort.com/",
        "label": "Wood's Tall Timber Resort",
        "loc": NP
    },
    {
        "url": "https://www.tuscorapark.com/",
        "label": "Tuscora Park",
        "loc": NP
    },
    {
        "url": "https://www.tripadvisor.com/Attraction_Review-g50282-d8390711-Reviews-The_RAC-Dover_Ohio.html",
        "label": "The RAC",
        "loc": DOVER
    },
    {
        "url": "https://paddletheriver.com/burning-river/",
        "label": "Burning River Adventures",
        "loc": CF
    },
    {
        "url": "https://www.cityofcf.com/places/water-works",
        "label": "Waterworks",
        "loc": CF
    },
    {
        "url": "https://jackentertainment.com/cleveland/",
        "label": "JACK Cleveland Casino",
        "loc": CLE
    }
];

function make_link(link_data) {
    return (
        <div className="infolink-div">
            <a class="infolink" href={link_data.url} target="_blank" rel="noopener noreferrer">
            {link_data.label}
            </a> - {link_data.loc}
        </div>
    )
}

export default function InfoLinks(category, link_list) {
    
    return (
        <div className="infolinks-container">

            {/* <h4>Museums</h4> */}
            <Collapsible trigger="Museums">
            { info_museum_links.map(make_link) }
            </Collapsible>

            {/* <h4>Historic Attractions</h4> */}
            <Collapsible trigger="Historic Attractions">
            { info_historic_links.map(make_link) }
            </Collapsible>

            {/* <h4>Parks</h4> */}
            <Collapsible trigger="Parks">
            { info_parks_links.map(make_link) }
            </Collapsible>

            {/* <h4>Theaters</h4> */}
            <Collapsible trigger="Theaters">
            { info_theaters_links.map(make_link) }
            </Collapsible>

            {/* <h4>Sports Venues</h4> */}
            <Collapsible trigger="Sports Venues">
            { info_sports_links.map(make_link) }
            </Collapsible>

            {/* <h4>Golf</h4> */}
            <Collapsible trigger="Golf">
            { info_golf_links.map(make_link) }
            </Collapsible>

            {/* <h4>Breweries</h4> */}
            <Collapsible trigger="Breweries">
            { info_brew_links.map(make_link) }
            </Collapsible>

            {/* <h4>Wineries</h4> */}
            <Collapsible trigger="Wineries">
            { info_wine_links.map(make_link) }
            </Collapsible>

            {/* <h4>Zoos</h4> */}
            <Collapsible trigger="Zoos">
            { info_zoo_links.map(make_link) }
            </Collapsible>

            {/* <h4>Other</h4> */}
            <Collapsible trigger="Other">
            { info_other_links.map(make_link) }
            </Collapsible>
            


        </div>
    )

}