import logo from './logo.svg';
import './App.css';
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { createBrowserRouter, RouterProvider, redirect } from 'react-router-dom';


// import "primereact/resources/themes/lara-light-blue/theme.css";

import "./prime_theme.css";

// import { useNavigate } from "react-router-dom";

import Home from './pages/Home';
import Cabins from './pages/Cabins';
import Houses from './pages/Houses';
import RSVP from './pages/RSVP';
import Info from './pages/Info';
import Registry from './pages/Registry';
import Admin from './pages/Admin';



const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('id_token');
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});




const httpLink = createHttpLink({ uri: './' }); // is this the right uri?

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

const rootLoader = async () => {
  const pageName = window.location.search
  if (pageName === "?start=info") {
    console.log("Redirecting to /info");
    return redirect("/info");
  }
  else if (pageName === "?start=cabins") {
    console.log("Redirecting to /cabins");
    return redirect("/cabins");
  }
  else if (pageName === "?start=houses") {
    console.log("Redirecting to /houses");
    return redirect("/houses");
  }
  else {
    return null;
  };
};

// const cabinLoader = async () => {
//   console.log("Cabin page loading.")
//   // PLACEHOLDER
//   //
//   // maybe set an env variable so users that visit the cabins page can get back 
//   //          - add Cabins to nav if they entered the site using /cabins
//   return null;
// }


const router = createBrowserRouter([
  {
    path: "/", 
    element: <Home />,
    loader: rootLoader
  },
  {
    path: "/info",
    element: <Info />
  },
  {
    path: "/rsvp",
    element: <RSVP />
  },
  {
    path: "/registry",
    element: <Registry />
  },
  {
    path: "/cabins",
    element: <Cabins />
    // loader: cabinLoader
  },
  {
    path: "/houses",
    element: <Houses />

  },
  {
    path: "/admin",
    element: <Admin />

  }

]);




function App() {

  return (
    <div className="App">
      <div>
        <RouterProvider router={router} />
        {/* <Router>
            <Routes>
              <Route exact path="/" element={<Home />}></Route>
              <Route exact path="/cabins" element={<Cabins />}></Route>
              <Route exact path="/rsvp" element={<RSVP />}></Route>
              <Route exact path="/info" element={<Info />}></Route>
              <Route exact path="/registry" element={<Registry />}></Route>
            </Routes>
        </Router> */}
      </div>
      
{/*
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
*/}
      
    </div>
  );
};

export default App;
