import React, { useState } from 'react';
import "./style.css";
import { Link } from 'react-router-dom';
import Hamburger from '../Hamburger';





export default function Header() {
    // const [hamburgerOpen, setHamburgerOpen] = useState(false);

    // const toggleHamburger = () =>{
    //     setHamburgerOpen(!hamburgerOpen)
    // }

    return (
        <div className="header-container">
        <header>
           <h1 className="page-title">Ryan & Krista's Wedding</h1>
          
           <h3>Magnolia Spring Farm<br />June 1, 2024</h3>
               
            <nav>
                {/* disabling all links for now - only Info is public. Cabins is direct only */}
                <Link to="/">Home</Link>
                <Link to="/info">Info</Link>
                <Link to="/rsvp">RSVP</Link>
                <Link to="https://honeyfund.com/site/ryanandkrista">Registry</Link> 

                {/* {[false, 'sm', 'md', 'lg', 'xl', 'xxl'].map((expand) => {
                    <Navbar key={expand} expand={expand} className="bg-body-tertiary mb-3">
                    <Container fluid>
                    
                    </Container>
                    </Navbar>
                })} */}
            </nav>

            {/* <div class="hamburger" onClick={toggleHamburger}>
                <Hamburger />
            </div> */}

        </header>
        </div>
    )

}