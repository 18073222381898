import React from 'react';
import Header from '../components/Header';
import { Link } from 'react-router-dom';
import AliceCarousel from 'react-alice-carousel';
import "react-alice-carousel/lib/alice-carousel.css";
import Countdown from 'react-countdown';

import cold_pub from '../assets/images/us/IMG_0043.JPG';
import greek from '../assets/images/us/IMG_0018.JPG';
import umbrella from '../assets/images/us/IMG_0032.JPG';
import woods from '../assets/images/us/IMG_0036.JPG';
import armgrab from '../assets/images/us/IMG_0031.JPG';
import parade from '../assets/images/us/IMG_0030.JPG';
import xmas from '../assets/images/us/IMG_0013.JPG';
import wedding from '../assets/images/us/IMG_0037.JPG';
import dessert from '../assets/images/us/IMG_0016.JPG';
import beach from '../assets/images/us/IMG_0020.JPG';
import airport from '../assets/images/us/IMG_0027.JPG';
import rafting from '../assets/images/us/IMG_0047.JPG';
import lakerock from '../assets/images/us/IMG_3445.JPG';


import './home.css';

const countdown_renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a complete state
      return (
        <div className="countdown completed">It's Wedding Day!</div>
      );
    } else {
      // Render a countdown
      return (
        <div className="countdown">
      
        We're Getting Married in <span className="cd-num">{days}</span> days! 
        
        </div>
      );
    }
  };

const Home = () => {

    const handleDragStart = (e) => e.preventDefault();

    // React.useEffect(() => {
    //     console.log("resizing");
    //     window.dispatchEvent(new Event("resize"));
    // }, []);


    


    {/* Add more images here */}
    const slides = [
        <img src={cold_pub} onDragStart={handleDragStart} class="slider-img" role="presentation" />,
        <img src={greek} onDragStart={handleDragStart} class="slider-img" role="presentation" />,
        <img src={umbrella} onDragStart={handleDragStart} class="slider-img" role="presentation" />,
        <img src={woods} onDragStart={handleDragStart} class="slider-img" role="presentation" />,
        <img src={armgrab} onDragStart={handleDragStart} class="slider-img" role="presentation" />,
        <img src={lakerock} onDragStart={handleDragStart} class="slider-img" role="presentation" />,
        <img src={parade} onDragStart={handleDragStart} class="slider-img" role="presentation" />,
        <img src={xmas} onDragStart={handleDragStart} class="slider-img" role="presentation" />,
        <img src={wedding} onDragStart={handleDragStart} class="slider-img" role="presentation" />,
        <img src={dessert} onDragStart={handleDragStart} class="slider-img" role="presentation" />,
        <img src={beach} onDragStart={handleDragStart} class="slider-img" role="presentation" />,
        <img src={airport} onDragStart={handleDragStart} class="slider-img" role="presentation" />
        
        
    ];



    const responsive_settings =  {
        0: {
            items: 1,
        },
        1024: {
            items: 3,
            itemsFit: 'contain',
        }
      };

    return (
        <div class="home-container">

            <Header />

            <div class='welcome-letter'>

                <h1 class="home-h1"><div className="countdown-div">
                <Countdown className="countdown" date="2024-06-01T00:00-05:00" renderer={countdown_renderer}/>
                </div></h1>

                

                <div class="carousel-container">
                    <AliceCarousel responsive={responsive_settings} autoPlay autoPlayInterval="5000" infinite items={slides} />
                        
                        
                   
                </div>


                <section>
                    
                    <article class='main-text'>
                        <div class='main-text-container'>
                        <p>
                            For us, this wedding means that for one day, we will have all the people in our lives that we love, together in one place.  Thank you in advance for making the trip to come celebrate us finding each other, and the joining of these two families together!
                        </p>

                        <p>
                            The ceremony and reception will be Saturday June 1, 2024, at a beautiful tulip farm in Magnolia, Ohio – Magnolia Spring Farm.
                        </p>

                        <p>
                            Although we met in California, we are both from Ohio, and since Ohio has always felt more like home to us, it seemed like the natural choice for our wedding!
                        </p>

                        <p>
                            We chose a flower farm as a tribute to Krista’s late grandmother, Jackie, and her love for flowers.
                        </p>

                        <p>
                            Kids are more than welcome!
                        </p>

                        <div id="call-to-action-container">
                            <p id="call-to-action-text">
                                Please be sure to check out the <span class="cta-actual"><Link to="/info">Info Page</Link></span> for information on Lodging!
                            </p>
                        </div>

                        <p>More information to come!</p>

                        <p>
                            -   Ryan and Krista
                        </p>
                        </div>
                    </article>




                    {/* Save the Date CALL TO ACTION - we want something prominent for people to click that allows them to enter their email address and name to confirm that they received the save the date 
        (we don’t want to use the verbiage “mailing list” sounds like we’re going to send them magazines or something haha) and tells them that we can send them updates as we update the website. */}
                    
                </section>






            </div>
        </div>
    )
};

export default Home;