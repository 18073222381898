import React from 'react';
import Header from '../components/Header';
// import StdRsvpForm from '../components/StdRsvpForm';
import RsvpForm from '../components/RsvpForm';
import SearchBarForm from '../components/SearchBarForm';

const RSVP = () => {
    return (
        <div>
        <Header />
        {/* Wonder if we link a Google form or something?   */}
        
        {/* <SearchBarForm /> */}

        {/* <StdRsvpForm /> */}
        <RsvpForm />

        </div>
    )
};

export default RSVP;