import React, { useState } from 'react'; 
import ReactModal from 'react-modal';

import { SelectButton } from 'primereact/selectbutton';
import { InputNumber } from 'primereact/inputnumber';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';



import { useNavigate } from "react-router-dom";

import emailjs from "@emailjs/browser";

// import EmailModal from "../EmailModal";

import './style.css';




const _SUCCESS_MSG = "Thank you! We've received your RSVP!";

const _FAIL_MSG = "Something went wrong, please try again later.";

// FIELD HELPER MSGS

const _GUEST_HELP_MSG = "separate multiple names with a comma and/or press enter";
const _FOOD_HELP_MSG = "ex. vegetarian, vegan, GF, etc.";
const _COMMENT_HELP_MSG = "";





const RsvpForm = () => {

    React.useEffect(() => emailjs.init("nNG845wXGhWuwZGAF"), []);



    const navigate = useNavigate();

    const [yesno, setYesNo] = useState(null);
    const [total, setTotal] = useState(null);
    const [name, setName] = useState('');
    const [guests, setGuests] = useState('');
    const [foodnotes, setFoodnotes] = useState('');
    const [comments, setComments] = useState('');

    const [guestFocus, setGuestFocus] = useState(false);
    const [foodFocus, setFoodFocus] = useState(false);
    const [commentFocus, setCommentFocus] = useState(false);

    const [modalIsOpen, setIsOpen] = React.useState(false);
    const [modal_msg, setModalMsg] = React.useState("");

    

    function setResultMsg(msg) {
        
        setModalMsg(msg);
        console.log("msg:", modal_msg);
    };

    
    function openModal() {
        setIsOpen(true);
    };

    function closeModal() {
        setIsOpen(false);
        navigate("/");
    };


   
    const sendEmail = async (msg) => {

      const mailParams = {
        to_name: "Ryan",
        from_name: "noreply@ryanandkristaswedding.com",
        message: msg
      };
    
      await emailjs.send(
        "service_fw1pquh",
        "template_24429cb",
        mailParams
      ).then(function(response) {
        console.log("Success!", response.status, response.text);
        setResultMsg(_SUCCESS_MSG);
        openModal();
      }, function(error) {
        console.log("Failed...", error);
        setResultMsg(_FAIL_MSG);
        openModal();
      });

    };
    
  

    const handleFormSubmit = async (e) => {
      e.preventDefault();
      console.log("event obj:", e);

      console.log("vals: ", yesno, name, guests, total, foodnotes, comments)      


      // if (yesno && name && total) {
      //   // Send a POST request to the API endpoint
      //   console.log("yesno name and total are set");
      //   await fetch('http://localhost:3001/send_rsvp', {
      //     method: 'POST',
      //     body: JSON.stringify({ yesno, name, guests, total, foodnotes, comments}),
      //     headers: { 'Content-Type': 'application/json' }
      //   })
      //     .then((res) => res.json())
      //     .then((res) => console.log("SUCCESS:", res))
      //     .catch((err) => console.log("ERROR:", err));
      // }

      // TEMP EMAIL API

      const msg = `RSVP RECEIVED:\nName: ${name}\nAccept/Decline: ${yesno}\nGuests: ${guests}\nTotal Attending: ${total}\nFood Notes: ${foodnotes}\nComments: ${comments}`;

      // make sure this logs correctly before enabling sendEmail
      console.log(msg);

      // will work EVEN IN DEV MODE - try to limit usage (200/mo. max)
      sendEmail(msg);

      // FOR TESTING
      // setResultMsg(_SUCCESS_MSG);
      // openModal();

      // setResultMsg(_FAIL_MSG);
      // openModal();


    };

    const yesno_opts = [
        {
            "name": "accept",
            "value": "accept"
        },
        {
            "name": "decline",
            "value": "decline"
        }
    ];



    return (
        <div className="rsvp-form-container">
          

          

            <div className='rsvp-form'>

              <div className='rsvp-form-text'>
              <p>
                Kindly
                RSVP
                before May 1st
              </p>
              </div>

                <form className="rsvp" onSubmit={handleFormSubmit}>


                {/* ADD HELPER TEXT TO FIELDS on hover  - textareas: separate names with <,> or <return> - food: please include name of person too -  */}



                
                {/* accept / decline - BOOL */}
                <SelectButton id='rsvp-yesno' className='rsvp-control' value={yesno} onChange={(e) => setYesNo(e.value)} optionLabel="name" optionValue="value" options={yesno_opts} />

                
                
                {/* your name - short STR */}
             
                <label className='rsvp-label' htmlFor="username">Your Name *</label>
                <br />
                <InputText id='rsvp-name' className='rsvp-control rsvp-text-control' value={name} onChange={(e) => setName(e.target.value)} />
                <br />

                {/* guest / children - multiline STR */}

                <div>
                <label className='rsvp-label' htmlFor="username">Guest / Children</label>
                <br />
                <InputTextarea id='rsvp-guests' className='rsvp-control rsvp-text-box' autoResize value={guests} onChange={(e) => setGuests(e.target.value)} onFocus={(e) => setGuestFocus(true)} onBlur={(e) => setGuestFocus(false)} rows={1} aria-describedby='guests-help' />
                <br />
                {guestFocus ? <small id="guests-help">{_GUEST_HELP_MSG}</small> : null}
                <br />
                </div>

                {/* total attending - INT */}
                <label className='rsvp-label' htmlFor="username">Total Attending *</label>
                <br />
                <InputNumber id='rsvp-total' className='rsvp-control rsvp-text-box' value={total} onValueChange={(e) => setTotal(e.value)} mode="decimal" min={0} max={9} />
                {/* <InputNumber value={value3} onValueChange={(e) => setValue3(e.value)} mode="decimal" showButtons min={1} max={9} /> */}
                <br />

                {/* food notes - multiline STR */}
                <label className='rsvp-label' htmlFor="username">Please list any dietary restrictions / allergies</label>
                <br />
                <InputTextarea id='rsvp-foodnotes' className='rsvp-control rsvp-text-box' autoResize value={foodnotes} onChange={(e) => setFoodnotes(e.target.value)} onFocus={(e) => setFoodFocus(true)} onBlur={(e) => setFoodFocus(false)} rows={1} aria-describedby='food-help' />
                <br />
                {foodFocus ? <small id="food-help">{_FOOD_HELP_MSG}</small> : null}
                <br />

                {/* comments - multiline STR */}
                <label className='rsvp-label' htmlFor="username">Comments</label>
                <br />
                <InputTextarea id='rsvp-comments' className='rsvp-control rsvp-text-box' autoResize value={comments} onChange={(e) => setComments(e.target.value)} onFocus={(e) => setCommentFocus(true)} onBlur={(e) => setCommentFocus(false)} rows={1} aria-describedby='comment-help' />
                <br />
                {commentFocus ? <small id="comment-help">{_COMMENT_HELP_MSG}</small> : null}
                <br />
                <div className='rsvp-footer'>
                
                <Button className='rsvp-control' label='Cancel' link />
                <Button className='rsvp-control' label='Submit' />
                <br />
                <br />
                *Required
                </div>
                </form>

            </div>
        
          <ReactModal className="modal-overlay" isOpen={modalIsOpen} onRequestClose={closeModal} contentLabel='Rsvp Result'>
            <div className='email-modal-content'>
              <div className='email-modal-msg'>
                {modal_msg}
              </div>
              <div className='email-modal-footer'>
                <button onClick={closeModal}>OK</button>
              </div>
            </div>
          </ReactModal>





          
      </div>
    );
};

export default RsvpForm; 