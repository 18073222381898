import React from 'react';
import { Link } from 'react-router-dom';
import './houses.css';
import BookingModal from '../components/Booking';
import AliceCarousel from 'react-alice-carousel';
import "react-alice-carousel/lib/alice-carousel.css";
import HouseLinks from '../components/HouseLinks';

import house1 from '../assets/images/salt_fork/CabinExt2-crop.jpg';
import house2 from '../assets/images/salt_fork/CabinExt2-crop.jpg';



const Houses = () => {


  return (
  <div>
<div class="cabin-header-container">
<header>
          
           <h1 class="page-title">Ryan & Krista's Wedding</h1>
           <h3>Magnolia Spring Farm<br />June 1, 2024</h3>
            <nav>
                {/* disabling all links for now - only Info is public. Cabins is direct only */}
                {/* <Link to="/">Home</Link> */}
                <Link to="/">Main Wedding Page</Link>

            </nav>
            
        </header>
        </div>
  <div class="cabins-container">
      <div class='cabin-info'>
        

        <h1 class="houses-h1">
          House Rentals near Magnolia, OH
        </h1>

        <h4 class="houses-h4"> Here are some rental houses near the venue. We're hoping everyone can find options near each other! <br /><br /> If nothing strikes your fancy, please feel free to check the area yourself on<a
            class='rental-link'
            href="https://www.airbnb.com/s/magnolia--oh/homes"
            target="_blank"
            rel="noopener noreferrer"
          >
            Airbnb
          </a>
          
         and 

          <a
            class='rental-link'
            href="https://www.vrbo.com/search?destination=Magnolia%2C Ohio%2C United States of America"
            target="_blank"
            rel="noopener noreferrer"
          >
            Vrbo
          </a>.</h4>
        <br />
        <h4 class="houses-h4">
        Our house is right next door to the venue, in Magnolia. 
        Kim (Krista's mom) and Doug (Ryan's dad) have each booked houses in Bolivar, which is about 15 minutes west of Magnolia.
        <br /><br />Most of the houses listed here are a similar distance 
        from Magnolia (10-15 miles) to the southwest. 
        There may be other good options to the north (towards Canton) 
        or south (towards Atwood Lake).

        <br /><br />
        </h4>



        <section class="houses-carousel-container">
     

 
        <HouseLinks />

       
        </section>

      </div>

    </div>
    </div>
  )
};

export default Houses;