import React, {useState, useEffect } from 'react';
import Header from '../components/Header';


import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';


import './admin.css';

const RsvpView = () => {

    const [rows, setRows] = useState([]);
    const [connected, setConnected] = useState(false);

    const dataLikeThis = [
        
    ];

    function getrow(data) {
        console.log('ROW data:', data)
        var rd = {
            "rid": data.rid,
            "name": data.name,
            "yesno": data.yesno,
            "total": data.total,
            "guests": data.guests,
            "foodnotes": data.foodnotes,
            "comments": data.comments,
            "timestamp": data.timestamp
        }

        return rd;
    }

    useEffect( () => {
         fetch('http://localhost:3001/getrsvps', '')
        .then((res) => res.json())
        .then((data) => {
            var rowlist = getrow(data.rsvp);
            setRows(rowlist);
        })
        .catch((err) => console.log(err));
    

    }, []);

 

    return (
        <div className="admin-rsvpview">
            <DataTable value={rows} tableStyle={{ minWidth: '50rem' }}>
                <Column field="rid" header="ID"></Column>
                <Column field="timestamp" header="Time"></Column>
                <Column field="name" header="Name"></Column>
                <Column field="yesno" header="Yes/No"></Column>
                <Column field="total" header="Total #"></Column>
                <Column field="guests" header="Guests/Children"></Column>
                <Column field="foodnotes" header="Food Notes"></Column>
                <Column field="comments" header="Comments"></Column>
            </DataTable>
        </div>
    )
}

const Admin = () => {

    // React.useEffect(() => emailjs.init("nNG845wXGhWuwZGAF"), []);





    return (
        <div className="admin-page">
          <Header />
          <RsvpView />
          
        </div>
    );
};































export default Admin; 