import React from "react";
import Header from "../components/Header";
import './registry.css';

function Registry() {

    return (
        <div class="registry-container">
            <Header />
            <h1 class="registry-h1">Coming Soon!</h1>
            {/* Will be direct link to registry, redirected upon clicking */}

            <p id="registry-text">The registry will be added to this page once it's ready! Stay Tuned!</p>

<section class="registry-fill">
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    

</section>

        </div>
    )
}

export default Registry;